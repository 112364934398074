<template>
  <div id="introduce">
    <div class="item_box_left">
      <div class="item_line"></div>
      <div class="item_title">企业使命</div>
      <div class="item_content">致力于为全球消费者提供方便好喝的茶饮产品</div>
    </div>
    <div class="item_box_mid">
      <div class="item_line"></div>
      <div class="item_title">企业愿景</div>
      <div class="item_content">让茶陪你每一天</div>
    </div>
    <div class="item_box_right">
      <div class="item_line"></div>
      <div class="item_title">企业介绍</div>
      <div class="item_content_a">
        旗下拥有无糖茶饮料品牌“让茶”和中式茶包品牌“他山集”。
        公司以“方便”和“好喝”为经营理念，将传统茶叶以瓶装茶和袋泡茶的现代便捷形式呈现，为年轻群体提供安全、健康、方便的茶饮方式。
      </div>
      <div class="item_content_b">
        让茶集团创立于2020年，是一家专注“方便健康茶”的现代专业快消品公司。 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyIntroduce",
  components: {},
  props: {},
  setup() {},
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
#introduce {
  @width: 62.5%;
  @min_width: 996px;
  width: @width;
  min-width: @min_width;
  margin: 120px auto 32px auto;
  display: flex;
  justify-content: space-around;
  .item_box_left {
    width: 140px;
    .item_content {
      width: 48px;
      height: 202px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      writing-mode: vertical-rl; //垂直方向，从左向右
      /* text-align: center; */
      letter-spacing: 3px;
      line-height: 30px;
      float: left;
    }
  }
  .item_box_mid {
    width: 130px;
    .item_content {
      width: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      writing-mode: vertical-rl; //垂直方向，从左向右
      /* text-align: center; */
      letter-spacing: 3px;
      line-height: 30px;
      float: left;
      margin-left: 30px;
    }
  }
  .item_box_right {
    width: 434px;
    .item_content_a {
      width: 209px;
      height: 276px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      writing-mode: vertical-rl; //垂直方向，从左向右
      /* text-align: center; */
      letter-spacing: 3px;
      line-height: 30px;
      float: left;
    }
    .item_content_b {
      width: 112px;
      height: 276px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      writing-mode: vertical-rl; //垂直方向，从左向右
      /* text-align: center; */
      letter-spacing: 3px;
      line-height: 30px;
      float: left;
      margin-left: 21px;
    }
  }
  .item_box_left,
  .item_box_mid,
  .item_box_right {
    .item_line {
      width: 2px;
      height: 145px;
      background: #000000;
      float: right;
    }
    .item_title {
      width: 33px;
      height: 145px;
      background: #45B035;
      font-size: 24px;
      font-weight: 500;
      color: white;
      writing-mode: vertical-lr; //垂直方向，从左向右
      text-align: center;
      letter-spacing: 10px;
      line-height: 33px;
      float: right;
      margin-right: 13px;
    }
  }
}
</style>
