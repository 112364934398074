<template>
  <div id="pc_index">
    <Header />
    <!-- benner -->
    <div class="banner">
      <swiper :options="swiperOption">
        <!-- 高山 -->
        <swiper-slide>
          <div class="banner_box">
            <img
              src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/banner_c_1.jpg"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
        <!-- 高山 -->
        <swiper-slide>
          <div class="banner_box">
            <img
              src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/banner_c_2.jpg"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
        <!-- 草本 -->
        <swiper-slide>
          <div class="banner_box">
            <img
              src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/banner_c_3.jpg"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
        
        <swiper-slide>
          <div class="banner_box">
            <img
              src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/banner_c_4.jpg"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide> 
        <swiper-slide>
          <div class="banner_box">
            <img
              src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/banner_c_5.jpg"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide> 
        <swiper-slide>
          <div class="banner_box">
            <img
              src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/banner_c_6.jpg"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <PCCompanyIntroduce />
    <!-- 让茶系列-高山花茶系列 -->
    <div class="series_box series_bg_gaoshahuacha" @click="toSerize(1)">
      <div class="series_title series_title_gaoshan">高山花茶系列</div>
      <div class="series_chuncha_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index/series_gaoshanhuacha.png"
          alt=""
        />
      </div>
      <div class="series_intrduce series_gaoshan_introduce">
        <div>
          <span>·</span>
          <span>自然清新</span>
        </div>
        <div style="margin-left: 0.52vw; margin-top: 2.52vw">
          <span>·</span>
          <span>茶味醇爽</span>
        </div>
      </div>
      <div class="learn_more">查看详情 ></div>
    </div>

    <!-- 让茶系列-高山系列 -->
    <div class="series_box series_bg_c" @click="toSerize(2)">
      <div class="series_title series_title_gaoshan">高山纯茶系列</div>
      <div class="series_chuncha_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index/series_p_c.png"
          alt=""
        />
      </div>
      <div class="series_intrduce series_gaoshan_introduce">
        <div>
          <span>·</span>
          <span>喝好茶</span>
        </div>
        <div style="margin-left: 0.52vw; margin-top: 2.52vw">
          <span>·</span>
          <span>高山茶</span>
        </div>
      </div>
      <div class="learn_more">查看详情 ></div>
    </div>

    <!-- 让茶系列-草本系列 -->
    <div class="series_box series_bg_e" @click="toSerize(3)">
      <div
        class="series_title series_title_gaoshan"
        style="left: 11.15vw;top: 9.906vw;"
      >
        草本茶系列
      </div>
      <div class="series_chuncha_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index/series_p_e.png"
          alt=""
        />
      </div>
      <div class="series_intrduce series_gaoshan_introduce">
        <div>
          <span>·</span>
          <span>清爽甘甜</span>
        </div>
        <div style="margin-left: 0.52vw; margin-top: 2.52vw">
          <span>·</span>
          <span>少苦涩</span>
        </div>
      </div>
      <div class="learn_more">查看详情 ></div>
    </div>

    <!-- 让茶系列-无糖果味茶 -->
    <div class="series_box series_bg_b" @click="toSerize(4)">
      <div class="series_title series_title_guocha" style="left: 11.2vw">
        无糖果味茶系列
      </div>
      <div class="series_guocha_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/202401/guowei_putao.png"
          alt=""
        />
      </div>
      <div class="series_intrduce series_guocha_introduce">
        <div>
          <span>·</span>
          <span>0糖0能量</span>
        </div>
        <div style="margin-left: 0.52vw; margin-top: 2.52vw">
          <span>·</span>
          <span>真茶真好喝</span>
        </div>
      </div>
      <div class="learn_more">查看详情 ></div>
    </div>
    <!-- 视频 -->
    <div class="video_box">
      <video controls>
        <!-- <source src="../../assets/ii_a.mp4" type="video/mp4" /> -->
        <source
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/website_a.mp4"
          type="video/mp4"
        />
      </video>
    </div>

    <!-- 关于让茶 -->
    <div class="aboutRangCha">
      <div class="inside">
        <div class="title">关于让茶</div>
        <div class="dec">
          <span>
            “让茶”即“请你来喝茶”。以“茶”为载体，让中国传统礼仪文化以及精致茶饮生活方式，在年轻族群中，焕发现代风貌。
          </span>
          <div class="model"></div>
        </div>
      </div>
    </div>
    <PCConncatUs />

    <Footer />
  </div>
</template>

<script>
import $ from "jquery";

// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import PCCompanyIntroduce from "@/components/CompanyIntroduce";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PCConncatUs from "@/components/PCConncatUs.vue";

export default {
  name: "Index",
  components: {
    swiper,
    swiperSlide,
    Header,
    Footer,
    PCCompanyIntroduce,
    PCConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        effect: "fade",
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
          renderBullet: function(index, className) {
            return '<span class="' + className + '">' + "</span>";
          },
        },
        // 设置点击箭头
        /* navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick:true
        }, */
      },
    };
  },
  methods: {
    /* handleScroll() {
      let height = $(document).scrollTop();
      // console.log("距离顶部距离", height);
      if (height >= 300 && height < 300 * 2) {
        // console.log("执行1");
        $(".animation_sugar_free_pure_tea .p_img_box").addClass(
          "animation_img"
        );
        $(".animation_sugar_free_pure_tea .p_dec").addClass("animation_dec");
        $(".animation_sugar_free_pure_tea .p_title").addClass(
          "animation_title"
        );
        $(".animation_sugar_free_pure_tea .p_arrow").addClass(
          "animation_arrow"
        );
        $(".animation_sugar_free_pure_tea .detail_btn").addClass(
          "animation_btn"
        );
      }
      if (height < 300 * 4 && height >= 300 * 3) {
        // console.log("执行2");
        $(".tea_without_candy_flavor .p_img_box").addClass("animation_img");
        $(".tea_without_candy_flavor .p_dec").addClass("animation_dec");
        $(".tea_without_candy_flavor .p_title").addClass("animation_title");
        $(".tea_without_candy_flavor .p_arrow").addClass("animation_arrow");
        $(".tea_without_candy_flavor .detail_btn").addClass("animation_btn");
      }
      if (height < 300 * 5 + 100 && height >= 300 * 4 + 100) {
        // console.log("执行2");
        $(".sugar_free_daily_series .p_img_box").addClass("animation_img");
        $(".sugar_free_daily_series .p_dec").addClass("animation_dec");
        $(".sugar_free_daily_series .p_title").addClass("animation_title");
        $(".sugar_free_daily_series .p_arrow").addClass("animation_arrow");
        $(".sugar_free_daily_series .detail_btn").addClass("animation_btn");
      }
      if (height < 300 * 6 + 100 && height >= 300 * 5 + 100) {
        // console.log("执行2");
        $(".sugar_free_mountain_series .p_img_box").addClass("animation_img");
        $(".sugar_free_mountain_series .p_dec").addClass("animation_dec");
        $(".sugar_free_mountain_series .p_title").addClass("animation_title");
        $(".sugar_free_mountain_series .p_arrow").addClass("animation_arrow");
        $(".sugar_free_mountain_series .detail_btn").addClass("animation_btn");
      }
    }, */
    toLearnMore() {
      sessionStorage.setItem("active_index", 1);
      this.$router.push("/product");
    },
    toSerize(index) {
      sessionStorage.setItem("active_index", 1);
      let active = `1-${index}`;
      sessionStorage.setItem("active", active);
      this.$router.push(`/product/list/rangcha?h=${index}`);
    },
  },
  created() {},
  mounted() {
    /* window.addEventListener("scroll", this.handleScroll); */
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
};
</script>

<style scoped lang="less">
#pc_index {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  .banner {
    width: 100%;
    /* height: calc(100vh - 90px); */
    margin-top: 64px;
    .banner_box {
      width: 100%;
      /* height: calc(100vh - 90px); */
      img {
        width: 100%;
      }
    }
    & /deep/ .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      /* border: #fff; */
      border-radius: 50%;
      background: #fff;
      opacity: 1;
    }
    & /deep/ .swiper-pagination-bullet-active {
      background: #45B035;
    }
    & /deep/ .swiper-slide-active {
      .banner_box {
        transform: scale(1.05);
        animation-name: banner_box;
        animation-duration: 4s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }
    }
    @keyframes banner_box {
      from {
        transform: scale(1.05);
      }
      to {
        transform: scale(1);
      }
    }
  }

  .product_box {
    width: 100%;
    .product_content {
      width: 100%;
      overflow: hidden;
      .p_item_left {
        /* width: 1052px; */
        width: 54.79%;
        height: 308px;
        margin-top: 169px;
        background: orange;
        float: left;
        position: relative;
        cursor: pointer;
        .model_box {
          width: 100%;
          height: 308px;
          background: #fff;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 1s;
          z-index: 997;
        }
        .position_box {
          width: 497px;
          height: 436px;
          position: absolute;
          top: -76px;
          position: relative;
          float: right;
          margin-right: -172px;
          z-index: 998;
          .p_img_box {
            width: 140px;
            height: 436px;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 998;
          }
          .animation_img {
            animation-name: p_img_box;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: 0s;
          }
          .p_dec {
            width: 46px;
            height: 250px;
            background: #ffffff;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            writing-mode: vertical-lr; //垂直方向，从左向右
            text-align: center;
            line-height: 46px;
            letter-spacing: 5px;
            opacity: 0;
            position: absolute;
            left: 153px;
            top: 34px;
            z-index: 998;
          }
          .animation_dec {
            animation-name: p_dec;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;
          }
          .p_title {
            width: 60px;
            height: 166px;
            background: #ffffff;
            font-size: 20px;
            font-weight: 400;
            color: #121212;
            writing-mode: vertical-lr; //垂直方向，从左向右
            text-align: center;
            line-height: 60px;
            letter-spacing: 5px;
            opacity: 0;
            position: absolute;
            left: 221px;
            top: 34px;
            z-index: 998;
          }
          .animation_title {
            animation-name: p_title;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;
          }
          .p_arrow {
            width: 0;
            height: 11px;
            background-image: url("../../assets/index/icon_arrow.png");
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0;
            position: absolute;
            left: 291px;
            top: 159px;
            z-index: 998;
            transition: all 1s;
          }
          .animation_arrow {
            width: 112px;
            margin-left: 6px;
            opacity: 1;
            transition: all 1s;
          }
          .detail_btn {
            width: 72px;
            font-size: 18px;
            font-weight: 400;
            color: #45B035;
            float: left;
            overflow: hidden;
            position: absolute;
            left: 425px;
            top: 156px;
            opacity: 0;
            z-index: 998;
            &::after {
              content: "";
              display: block;
              margin-top: 5px;
              height: 1px;
              background-color: #45B035;
              transition: all 1s;
            }
          }
          .animation_btn {
            animation-name: detail_btn;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;
          }
        }
        &:hover {
          /* opacity: 0.7;
          transition: all 1s; */
          .model_box {
            opacity: 0.3;
          }
          .position_box {
            .p_arrow {
              transform: translateX(10px);
            }
          }
          .detail_btn {
            &::after {
              content: "";
              display: block;
              margin-top: 5px;
              height: 1px;
              background-color: #45B035;
              transform: translateX(72px);
            }
          }
        }
        @keyframes p_img_box {
          from {
            opacity: 0;
            transform: translateY(0);
          }
          to {
            opacity: 1;
            transform: translateY(10px);
          }
        }
        @keyframes p_dec {
          from {
            opacity: 0;
            transform: translateY(0);
          }
          to {
            opacity: 1;
            transform: translateY(10px);
          }
        }
        @keyframes p_title {
          from {
            opacity: 0;
            transform: translateY(0);
          }
          to {
            opacity: 1;
            transform: translateY(10px);
          }
        }
        @keyframes detail_btn {
          0% {
            opacity: 0;
          }
          25% {
            opacity: 0.25;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      }
      .p_item_right {
        /* width: 1052px; */
        width: 54.79%;
        height: 308px;
        margin-top: 169px;
        background: orange;
        float: right;
        position: relative;
        cursor: pointer;
        .model_box {
          width: 100%;
          height: 308px;
          background: #fff;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 1s;
          z-index: 997;
        }
        .position_box {
          width: 497px;
          height: 436px;
          top: -76px;
          position: relative;
          margin-left: -172px;
          z-index: 998;
          .p_img_box {
            width: 140px;
            height: 436px;
            opacity: 0;
            position: absolute;
            left: 369px;
            top: 0;
            z-index: 998;
          }
          .animation_img {
            animation-name: p_img_box;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: 0s;
          }
          .p_dec {
            width: 46px;
            height: 250px;
            background: #ffffff;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            writing-mode: vertical-lr; //垂直方向，从左向右
            text-align: center;
            line-height: 46px;
            letter-spacing: 5px;
            opacity: 0;
            position: absolute;
            left: 213px;
            top: 34px;
            z-index: 998;
          }
          .animation_dec {
            animation-name: p_dec;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;
          }
          .p_title {
            width: 60px;
            height: 166px;
            background: #ffffff;
            font-size: 20px;
            font-weight: 400;
            color: #121212;
            writing-mode: vertical-lr; //垂直方向，从左向右
            text-align: center;
            line-height: 60px;
            letter-spacing: 5px;
            opacity: 0;
            position: absolute;
            left: 281px;
            top: 34px;
            z-index: 998;
          }
          .animation_title {
            animation-name: p_title;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;
          }
          .p_arrow {
            width: 0;
            height: 11px;
            background-image: url("../../assets/index/icon_arrow_left.png");
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0;
            position: absolute;
            left: 85px;
            top: 159px;
            z-index: 998;
          }
          .animation_arrow {
            width: 112px;
            margin-left: 6px;
            opacity: 1;
            transition: all 1s;
          }
          .detail_btn {
            width: 72px;
            font-size: 18px;
            font-weight: 400;
            color: #45B035;
            float: left;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 156px;
            opacity: 0;
            z-index: 998;
            &::after {
              content: "";
              display: block;
              margin-top: 5px;
              height: 1px;
              background-color: #45B035;
            }
          }
          .animation_btn {
            animation-name: detail_btn;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;
          }
        }
        &:hover {
          .model_box {
            opacity: 0.3;
          }
          .position_box {
            .p_arrow {
              transform: translateX(-10px);
              transition: all 1s;
            }
          }
          .detail_btn {
            &::after {
              content: "";
              display: block;
              margin-top: 5px;
              height: 1px;
              background-color: #45B035;
              transform: translateX(-72px);
              transition: all 0.5s;
            }
          }
        }
        @keyframes p_img_box {
          from {
            opacity: 0;
            transform: translateY(0);
          }
          to {
            opacity: 1;
            transform: translateY(10px);
          }
        }
        @keyframes p_dec {
          from {
            opacity: 0;
            transform: translateY(0);
          }
          to {
            opacity: 1;
            transform: translateY(10px);
          }
        }
        @keyframes p_title {
          from {
            opacity: 0;
            transform: translateY(0);
          }
          to {
            opacity: 1;
            transform: translateY(10px);
          }
        }
        @keyframes detail_btn {
          0% {
            opacity: 0;
          }
          25% {
            opacity: 0.25;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      }
      .product_background_a {
        background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index_b_1.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .product_background_b {
        background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index_b_2.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .product_background_c {
        background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index_b_3.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .product_background_d {
        background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index_b_4.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .learn_more {
      width: 280px;
      height: 56px;
      background: #45B035;
      border-radius: 6px;
      margin: 101px auto 0 auto;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 56px;
      text-align: center;
      position: relative;
      cursor: pointer;
      .arrow {
        position: absolute;
        top: 0;
        right: -80px;
      }
      &:hover {
        .arrow {
          transform: translateX(10px);
          transition: all 1s;
        }
      }
    }
  }
  .series_box {
    width: 100vw;
    height: 35.885vw;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 11.458vw;
    position: relative;
    .series_title {
      font-size: 2.5vw;
      font-weight: 300;
      writing-mode: vertical-lr; //垂直方向，从左向右
      text-align: center;
      position: absolute;
      letter-spacing: 6px;
    }
    .series_title_gaoshan {
      color: #3d6c18;
      left: 11.4vw;
      top: 10.781vw;
    }
    .series_title_chuncha {
      color: #4b5a1d;
      left: 11.2vw;
      top: 10.781vw;
    }
    .series_title_guocha {
      color: #94233d;
      left: 11.4vw;
      top: 8.906vw;
    }
    .series_title_rucha {
      color: #0069a5;
      left: 11.4vw;
      top: 8.906vw;
    }
    .series_title_meiri {
      color: #a94215;
      left: 11.4vw;
      top: 8.906vw;
    }
    .series_title_gaoshan {
      color: #516a1d;
      left: 11.4vw;
      top: 8.906vw;
    }
    .series_chuncha_img {
      width: 12.24vw;
      height: 43.958vw;
      position: absolute;
      left: 27.292vw;
      top: -5.677vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .series_guocha_img {
      width: 14.688vw;
      /* height: 45.51vw; */
      position: absolute;
      left: 25.365vw;
      top: -3.802vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .series_rucha_img {
      width: 16.667vw;
      height: 41.042vw;
      position: absolute;
      left: 25.3125vw;
      top: -5.677vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .series_meiri_img {
      width: 12.552vw;
      height: 48.177vw;
      position: absolute;
      left: 27.292vw;
      top: -5.677vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .series_gaoshan_img {
      width: 12.552vw;
      height: 45.208vw;
      position: absolute;
      left: 27.292vw;
      top: -5.677vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .series_intrduce {
      width: 7.375vw;
      position: absolute;
      div {
        font-size: 2.083vw;
        font-weight: bold;
        float: left;
        writing-mode: vertical-lr; //垂直方向，从左向右
        text-align: center;
        letter-spacing: 6px;
      }
    }
    .series_rucha_introduce {
      top: 10.26vw;
      right: 35.365vw;
      div {
        color: #0069a5;
      }
    }
    .series_guocha_introduce {
      top: 10.26vw;
      right: 35.365vw;
      div {
        color: #94233d;
      }
    }
    .series_chuncha_introduce {
      top: 10.26vw;
      right: 35.365vw;
      div {
        color: #4b5a1d;
      }
    }
    .series_gaoshan_introduce {
      top: 10.26vw;
      right: 35.365vw;
      div {
        color: #3d6c18;
      }
    }
    .series_meiri_introduce {
      top: 10.26vw;
      right: 35.365vw;
      div {
        color: #a94215;
      }
    }
    .series_goashan_introduce {
      top: 10.26vw;
      right: 35.365vw;
      div {
        color: #516a1d;
      }
    }
    .learn_more {
      font-size: 1.042vw;
      font-weight: 600;
      color: #6c6c6b;
      position: absolute;
      right: 13.385vw;
      bottom: 1.615vw;
    }
  }
  .series_bg_a {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index/series_b_a.png");
  }
  .series_bg_b {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index/series_b_b.png");
  }
  .series_bg_c {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index/series_b_c.jpg");
  }

  .series_bg_gaoshahuacha {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index/series_gaoshanhuacha_bg.jpg");
  }
  .series_bg_d {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index/series_b_d.jpg");
  }
  .series_bg_e {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/index/series_b_e.jpg");
  }
  .video_box {
    /* width: 100%; */
    width: 62.5%;
    min-width: 968px;
    // height: 500px;
    margin: 150px auto 80px;
    video {
      width: 100%;
      /* height: 100%; */
      object-fit: cover;
    }
  }
  .aboutRangCha {
    width: 100%;
    height: 380px;
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/20220310/rangcha_footer.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 81px;
    .inside {
      /* width: 1200px; */
      height: 380px;
      margin: 0 auto;
      position: relative;
      .title {
        width: 60px;
        height: 124px;
        background: #ffffff;
        position: absolute;
        top: -31px;
        left: 64px;
        z-index: 998;
        font-size: 20px;
        font-weight: 500;
        color: #121212;
        writing-mode: vertical-lr;
        text-align: center;
        line-height: 60px;
        letter-spacing: 5px;
      }
      .dec {
        width: 323px;
        height: 160px;
        font-size: 18px;
        font-weight: 400;
        /* color: #ffffff; */
        color: #121212;
        line-height: 36px;
        /* writing-mode: vertical-lr; */
        text-align: center;
        letter-spacing: 5px;
        position: absolute;
        padding: 8px;
        top: 43px;
        left: 260px;
        z-index: 998;
        .model {
          width: 100%;
          height: 160px;
          background: #fff;
          opacity: 0.7;
          position: absolute;
          top: 0;
          right: 0;
          z-index: -1;
        }
      }
    }
  }
}
</style>
